import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "z-index"
    }}>{`Z-Index`}</h1>
    <p>{`$zindex-dropdown: 1000 !default;`}</p>
    <p>{`$zindex-sticky: 1020 !default;`}</p>
    <p>{`$zindex-fixed: 1030 !default;`}</p>
    <p>{`$zindex-modal-backdrop: 1040 !default;`}</p>
    <p>{`$zindex-modal: 1050 !default;`}</p>
    <p>{`$zindex-popover: 1060 !default;`}</p>
    <p>{`$zindex-tooltip: 1070 !default;`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      